.termination-dashboard
{
    min-width: 1024px; 
    align-content: center; 
    margin: 0 2.5rem 2.5rem 2.5rem;
}

.termination-dashboard * .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: calc(100vh - 200px);
    animation: example 1s linear 0s infinite alternate both;
}

@keyframes example {
    from {
      font-weight: 100;
      color: #eee;
      kerning: 1rem;
    }
    to {
      font-weight: 800;
      color: #000;
      kerning: 1rem;
    }
  }

.termination-dashboard * table {
    /* border-spacing: 0; */
    empty-cells: show;
}

.termination-dashboard * table{
    margin-top: 1.5rem;
    border-left: 3px solid #eee;
}

.grouped-by-field, .forecasted{
    width: 10%;
}
.grouped-by-field > table, .forecasted > table{
    border-left: 0;
    width: 100%;
    table-layout: fixed;
}

.termination-dashboard * .data-presentation {
    width: 30%;
}

.termination-dashboard * .data-presentation table {
    width: 90%;
    margin-left: 5%;
}

.termination-dashboard * .data-presentation table tbody tr td,
.termination-dashboard * .grouped-by-field table tbody tr th {
    box-sizing: border-box;
    border-bottom: 1px solid transparent;
}

.termination-dashboard * .data-presentation table tbody tr.hovered td,
.termination-dashboard * .grouped-by-field table tbody tr.hovered th
{
    font-weight: 700;
    border-bottom: 1px solid #ddd;
}

.termination-dashboard * .grouped-by-field table tbody tr.hovered th {
    color: #333;
    background: #eee;
}

.termination-dashboard * table tbody tr:nth-child(odd) td,
.termination-dashboard * table tbody tr:nth-child(odd) th 
{
    background: #efefef;
    empty-cells: show;
}

.termination-dashboard * table thead tr th {
    text-align: right;
    empty-cells: show;
    height: 1.35rem;
    min-height: 1.35rem;
}

.termination-dashboard * table tfoot tr td, 
.termination-dashboard * table tfoot tr th
{
    text-align: right;
    font-style: italic;
    font-weight: 500;
    empty-cells: show;
}

