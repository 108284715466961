.fullscreen {
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    height: 100vh - 140px;
}
.legend {
    padding-right: 1em;
    overflow: auto;
}
.legend ol {
    margin-left: 0.25em;
    padding: 0px;
}
.legend ol li {
    margin: 0px;
    padding: 0px;
}
.legend-item {
    display: block;
    text-overflow: ellipsis;
    overflow: none;
    text-wrap: nowrap;
    margin-right: 1em;
}