.nav-list-link {
    text-decoration: none; 
    color: #111; 
    font-weight: 600;
}

.nested-routes {
    padding: 1rem;
}
.nested-routes ul {
    list-style-type: none;
    padding: 0;
}

.nested-routes ul li > ul {
    margin-left: 1rem;
}

.nested-routes * a {
    color: #000;
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;
}