table.query-speed-report {
    width: 100%;
}
.query-speed-report thead tr th {
    cursor: pointer;
}
.query-speed-report tbody tr{
    vertical-align: top;
    border-top: 1px solid lightgrey;
}

.query-speed-report tbody tr td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.query-speed-report tbody tr td {
    width: 12%;
    text-align: right;
}

.query-speed-report tbody tr td:first-child {
    width: 64%;
    text-align: left;
}
